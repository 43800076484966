.emailListItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-height: 50px;
}

.colorBlock {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  flex-shrink: 0;
}

.hiddenColorPicker {
  display: none;
}

.emailText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
