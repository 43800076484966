.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button {
    margin: 0 10px;
    padding: 10px 20px;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s;
}

.button[activated="true"] {
    background-color: #000;
    color: #fff;
}